import React from "react";
import Page from "../Page";
import Guide from "./Guide";
import { guide_nh } from "../lib/constants";

export default function GuideNh() {

  return (
    <Page>
      <div className="Guide">
        <Guide guide={guide_nh} />
      </div>
    </Page>
  )
}
