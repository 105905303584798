import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import Page from "../Page";
import Loader from './Loader';
// import { useNotify } from '../contexts/NotifyContext';
import { nanoid } from "nanoid";
import Images from "./Images";
import ArtsStyles from "./ArtsStyles";
import { oaiImagePromptEndpoint } from "../lib/constants";
import './ImagePrompt.scss';
import Select from "react-select";

export default function ImagePrompt() {


  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState('oil painting');
  const [genre, setGenre] = useState('portrait');
  const [style, setStyle] = useState('Impressionist');
  const [styles, setStyles] = useState([]);
  const [color, setColor] = useState('');
  const [more, setMore] = useState(false);

  // const { setNotification } = useNotify();
  const [formula, setFormula] = useState('');

  const n = 3;
  const size = '1024x1024';

  const generateImages = async (p) => {
    setMore(false);

    const prompt = 'A quality, high detail, professional ' + media + ' ' + genre + ' depicting ' + p + ' in ' + style + ' style. Dominant color: ' + color + '.';
    // console.log(prompt);

    setLoading(true);

    try {
      const response = await fetch(oaiImagePromptEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
        },
        body: JSON.stringify({
          prompt,
          n,
          size,
        })
      });

      const data = await response.json();
      // console.log(data);

      if (data.data.length > 0) {
        data.data.map(d => ({
          id: nanoid(),
          url: d.url,
          created: data.created
        }));

        setImages(prev => prev ? [...data.data, ...prev] : data.data);
      }

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  

  useEffect(() => {

    const getStyles = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://dash.martlet.live/wp-json/dash/v1/styles', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(`media:${process.env.REACT_APP_MEDIA_KEY}`),
          },
        });
  
        const data = await response.json();
        console.log(data);
  
        if (data.length > 0)
          setStyles(data.sort((a, b) => b.label.localeCompare(a.label)));
  
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }

    ( async () => getStyles() )();
  }, []);


  return (
    <Page>
      <div className="ImagePrompt">

        <div className="box-row">

          <div className="formula">
            <input
              placeholder="Type your prompt here"
              onChange={(e) => setFormula(DOMPurify.sanitize(e.target.value.trim()))}
              onKeyUp={(e) => e.key === 'Enter' && generateImages(formula)}
            />
          </div>
          <button className="mtl-btn" onClick={() => generateImages(formula)}>Create</button>
        </div>

        <span  className="more" onClick={() => setMore(!more)}>More options</span>

        { more && (
        <div className="selects">
          <Select
            className="select"
            options={[
              { value: 'oil painting', label: 'oil painting' },
              { value: 'watercolor painting', label: 'watercolor painting' },
              { value: 'acrylic painting', label: 'acrylic painting' },
              { value: 'pastel painting', label: 'pastel painting' },
              { value: 'ink painting', label: 'ink painting' },
              { value: 'pencil drawing', label: 'pencil drawing' },
              { value: 'charcoal drawing', label: 'charcoal drawing' },
              { value: 'pen drawing', label: 'pen drawing' },
              { value: 'digital painting', label: 'digital painting' },
              { value: 'digital drawing', label: 'digital drawing' },
              { value: 'photograph', label: 'photograph' },
              { value: 'sculpture', label: 'sculpture' },
            ]}
            placeholder={'Select media'}
            onChange={(e) => setMedia(e.value)}
          />

          <Select
            className="select"
            options={[
              { value: 'portrait', label: 'portrait' },
              { value: 'landscape', label: 'landscape' },
              { value: 'still life', label: 'still life' },
              { value: 'abstract', label: 'abstract' },
              { value: 'figurative', label: 'figurative' },
            ]}
            onChange={(e) => setGenre(e.value)}
            placeholder={'Select genre'}

          />

          <ArtsStyles setStyle={setStyle} styles={styles} />

          <Select
            className="select"
            options={[
              { value: 'blue', label: 'blue' },
              { value: 'red', label: 'red' },
              { value: 'green', label: 'green' },
              { value: 'yellow', label: 'yellow' },
              { value: 'orange', label: 'orange' },
              { value: 'purple', label: 'purple' },
              { value: 'pink', label: 'pink' },
              { value: 'brown', label: 'brown' },
              { value: 'black', label: 'black' },
              { value: 'white', label: 'white' },
              { value: 'grey', label: 'grey' },
              { value: 'black and white', label: 'black and white' },
            ]}
            placeholder={'Select color'}
            onChange={(e) => setColor(e.value)}
          />

        </div>
        )}

        {loading ? <Loader /> :
          <Images images={images} />
        }
      </div>
    </Page>
  );
}