import React from "react";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import logoImg from '../assets/images/logo-martlet.png';
import "./Toolbar.scss";

export default function Toolbar({t}) {

  const navigate = useNavigate();



  const options = [
    {
      label: 'Generative Art',
      options: [
        { label: 'Image from Prompt', value: 'image-from-prompt' },
        { label: 'Image from Templates', value: 'image-from-templates' },
      ],
    },
    {
      label: 'Chatbots',
      options: [
        { label: 'Nové Hrady Guide', value: 'nh-guide' },
        { label: 'Socratic Dialogues', value: 'socratic-ai' },
      ],
    },
  ];


  return (
    <div className={`toolbar--wrapper`}>
      <div className="toolbar">
        <a href="/">
          <span className="edge-left">
            <img className="logo-img" src={logoImg} alt="" />
            <span className="logo-text">AI&nbsp;Playground</span>
          </span>
        </a>

        <span className="center">
          <a href="https://martlet.live/" className="mtl-btn">Blog</a>
        </span>
        
        <span className="edge-right">
          <Select
            width="200px"
            options={options}
            onChange={(e) => navigate('/' + e.value)}
            placeholder={t('Select project')}
          />
        </span>
      </div>
    </div>
  );
}
