import React, { useState, useEffect, useRef } from "react";
import Loader from './Loader';
import { nanoid } from 'nanoid';
import { useNotify } from "../contexts/NotifyContext";
import { oaiChatEndpoint } from "../lib/constants";
import Avatar from "./Avatar";
import  './Guide.scss';

export default function Guide({guide}) {

  const {setNotification} = useNotify();
  const [speech, setSpeech] = useState('');
  const [chat, setChat] = useState([]);
  const [convo, setConvo] = useState(nanoid());
  const [loading, setLoading] = useState(false);
  const inRef = useRef(null);
  
  const handleSpeech = async (speech) => {
    setLoading(true);

    try {
      const intro = guide.intro;

      const response = await fetch(oaiChatEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            { "role": "system", "content": intro },
            ...chat,
            { "role": 'user', "content": speech }
          ],
          max_tokens: 200,
          user: convo,
        })
      });

      const data = await response.json();
      const lastSaid = data.choices[0].message.content;

      setChat(prev => [...prev, { role: 'user', content: speech }, { role: 'assistant', content: lastSaid }]);
      setSpeech('');
      inRef.current.value = '';

    } catch (error) {
      console.log(error);
      setNotification('No response from the server.');
    } finally {
      setLoading(false);
    }
  }

  const reset = () => {
    setSpeech('');
    setChat([]);
    setConvo(nanoid());
  }

  useEffect(() => {
    reset();
  }, []);



  return (
    <div className="Producer guide">

      <div className="box-row">
        <header>
          <div className="col-20">  
            <h3>{guide.name}</h3>
          </div>

          <div className="col-60">
            <img src={guide.image} alt={guide.name} id={guide.id} />
          </div>

          <div className="col-20">
           {guide.description}
          </div>
        </header>
      </div>

      {loading && <Loader />}

        <div className="box-row chat">
          {chat?.map((item, i) => 
            <div key={nanoid()} className={`chat__item ${item.role}`}>
              {item.role === 'user' && <Avatar/>}
              {item.role === 'assistant' && <Avatar image={guide.image} />}
              <p>{item.content}</p>
            </div>
          )}
          <div className="talkbox">
            <input
              ref={inRef}
              className='talkbox__input'
              placeholder="Your question"
              onChange={e => setSpeech(e.target.value)}
              onKeyUp={e => e.key === 'Enter' && handleSpeech(speech)}
            />
            <div className="meta">
              <button className="mtl-btn primary s" onClick={() => handleSpeech(speech)}>{'Send'}</button>
            </div>
          </div>
      </div>
      
    </div>
  );
}

