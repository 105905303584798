import React, { useEffect, useState } from "react";
import { useNotify } from '../contexts/NotifyContext';
import Loader from './Loader';
import { nanoid } from "nanoid";
import Images from "./Images";
import Page from "../Page";
import ArtsStyles from "./ArtsStyles";
import './Producer.scss';

export default function ImageTemplates() {

  const { setNotification } = useNotify();
  const [templates, setTemplates] = useState([]);
  const [scene, setScene] = useState(''); // template id
  const [character, setCharacter] = useState(''); // template id
  const [style, setStyle] = useState('impressionist');
  const [styles, setStyles] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const details = 'A quality, high detail painting of ';

  const passRequest = async () => {

    setLoading(true);

    try {
      const response = await fetch('https://dash.martlet.live/wp-json/dash/v1/pass_request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ' + btoa(`media:${process.env.REACT_APP_MEDIA_KEY}`),
        },
        body: JSON.stringify({
          scene,
          character,
          details,
          style,
        })
      });

      const data = await response.json();
      console.log(data);

      if (data.data.length > 0) {
        data.data.map(d => ({
          id: nanoid(),
          url: d.url,
          created: data.created
        }));

        setImages(prev => prev ? [...data.data, ...prev] : data.data);
      }

    } catch (error) {
      setNotification('No response from the server.');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await fetch('https://dash.martlet.live/wp-json/dash/v1/templates', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(`media:${process.env.REACT_APP_MEDIA_KEY}`),
          },
        });
        const data = await response.json();
        // console.log(data);
        setTemplates(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }

      setLoading(true);
      try {
        const response = await fetch('https://dash.martlet.live/wp-json/dash/v1/styles', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Basic ' + btoa(`media:${process.env.REACT_APP_MEDIA_KEY}`),
          },
        });

        const data = await response.json();
        console.log(data);

        if (data.length > 0)
          setStyles(data.sort((a, b) => b.label.localeCompare(a.label)));

      } catch (error) {
        setNotification('No response from the server.');
      } finally {
        setLoading(false);
      }


    })();
  }, [setNotification]);

  return (
    <Page>

      <div className="Producer templates full-box">

        <div className="mode">
          <div className="row">
            <div className="composite-prompt">
              {!character && !scene ?
                <span className="full-width">Choose a character and a scene from the lists below</span>
                :
                <div className="boxed">
                  <div className="composite-prompt--character">
                    {character && templates.prompts.find(p => p.id === character) &&
                      <span>Character: {templates.prompts.find(p => p.id === character).title}</span>
                    }
                  </div>
                  <div className="composite-prompt--scene">
                    {scene && templates.prompts.find(p => p.id === scene) &&
                      <span>Scene: {templates.prompts.find(p => p.id === scene).title}</span>
                    }
                  </div>
                </div>
              }
            </div>

            <ArtsStyles setStyle={setStyle} styles={styles} prompts={templates?.prompts} character={character} />

            <button className="mtl-btn" onClick={() => {
              passRequest();
            }}>Create</button>

          </div>
        </div>

        <div className="full-box">
          {loading ? <Loader /> :
            <Images images={images} />
          }
        </div>

        <div className="templates">
          {loading ? <Loader /> : (
            templates && templates.all_terms?.map(tag => (
              <div className="group" key={nanoid()}>
                <h3>{tag}</h3>
                <div className="characters">
                  <h4>Characters</h4>
                  {templates.prompts?.filter(tp => tp.terms.includes(tag) && tp.type === 'character').map(t => (
                    <div className="item" key={nanoid()} onClick={() => {
                      setCharacter(t.id)
                    }}>
                      {t.title}
                    </div>
                  ))}
                </div>

                <div className="scenes">
                  <h4>Scenes</h4>
                  {templates.prompts?.filter(tp => tp.terms.includes(tag) && tp.type === 'scene').map(t => (
                    <div className="item" key={nanoid()} onClick={() => {
                      setScene(t.id)
                    }}>
                      {t.title}
                    </div>
                  ))}
                </div>

              </div>
            ))
          )}
        </div>

      </div>
    </Page>

  );
}