import Select from 'react-select';

export default function ArtsStyles({ styles, setStyle, prompts = null, character = null }) {

  const item = character && prompts && prompts.find(p => p.id === character);
  const recStyles = item?.recommended ?? [];

  const customStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      height: '36px',
      flex: '0 0 100%',
      minWidth: '100%',
      display: 'inline-flex',
    }),
    option: (provided, state) => {
      const optionLabel = state.data.label;
      const isHighlighted = recStyles.includes(optionLabel);
      return {
        ...provided,
        backgroundColor: isHighlighted ? '#e8c450' : 'white',
      };
    },
  };
  return (
    <Select
      className="select"
      options={styles}
      styles={customStyles}
      onChange={(e) => setStyle(e.value)}
      placeholder={'Select style'}
    />
  );
}
