import React from "react";
import Page from '../Page';
import Dialogue from './Dialogue';
import { socrates, plato } from '../lib/constants';


export default function Socrates() {

  return (
    <Page>
      <div className="Dialogue">
        <Dialogue questioner={socrates} answerer={plato} />
      </div>
    </Page>
  )
}
