import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import GuideNh from "./components/GuideNh";
import Socrates from "./components/Socrates";
import ImageTemplates from "./components/ImageTemplates";
import Front from "./components/Front";
import NotFound from "./components/NotFound";
import ImagePrompt from "./components/ImagePrompt";

export default function App() {

  return (
    <>
      <div className={`app`}>

        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Front />} />
          <Route path="/nh-guide" element={<GuideNh />} />
          <Route path="/socratic-ai" element={<Socrates />} />
          <Route path="/image-from-templates" element={<ImageTemplates />} />
          <Route path="/image-from-prompt" element={<ImagePrompt />} />
          <Route path="" element={<Navigate to="/notfound" />} />
        </Routes>
      </div>
    </>
  );
}


