import React from "react";
import { saveAs } from "file-saver";
import { nanoid } from "nanoid";


export default function Images({images}) {

  const save = (url) => {
    const img = images.find(i => i.url === url);
    saveAs(img.url, img.created);
  }

  return (
    <div className="produced">
      {images.map( img => (
        <div className="item" key={nanoid()} onClick={() => save(img.url)}>
          <img src={img.url} alt="" />
        </div>
      ))}
    </div>
  )
}