import React from 'react';
import { Helmet } from 'react-helmet-async';
import './NotFound.scss'; 


export default function NotFound() {
  return (
    <>
    <Helmet>
      <title>Not Found</title>
      <meta name="description" content="Not Found" />
    </Helmet>

    <div className={`not-found`}>
      <section>
      <h1>Lost</h1>
      <verse>
        Like bare gold<br />
        moon shines in darkening west <br />
        spread crystal wings <br />
        let fresh air in your chest <br />
        fern leaf you hold  <br />
        will lead you through the quest
      </verse>
      </section>
    </div>
    </>
  );
}
