import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { AppProvider } from "./contexts/AppContext";
import { NotifyProvider } from "./contexts/NotifyContext";
import App from './App';
import "./app.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <NotifyProvider>
        <AppProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppProvider>
      </NotifyProvider>
    </HelmetProvider>
  </React.StrictMode>
);

