import React, {
  useState,
  createContext,
  useContext,
} from "react";

const AppContext = createContext({
  result: null,
  setResult: () => {},
  selectedImage: '',
  selectedMask: '',
  setSelectedImage: () => {},
  setSelectedMask: () => {},
  artboard: {
    width: 0, 
    height: 0,
  },
  setArtboard: () => {},
  selectedTitle: {
    url: '',
    width: 0,
    height: 0,
  },
  setSelectedTitle: () => {},
});

function AppProvider({ children }) {

  const [selectedImage, setSelectedImage] = useState(null);
  const [result, setResult] = useState(null);
  const [selectedMask, setSelectedMask] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [artboard, setArtboard ] = useState({
    width: 0, 
    height: 0,
  });

  const values = {
    result,
    setResult,
    selectedImage,
    setSelectedImage,
    selectedMask,
    setSelectedMask,
    selectedTitle,
    setSelectedTitle,
    artboard,
    setArtboard,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
}

export { AppProvider, AppContext };

export const useAppContext = () => useContext(AppContext);
