export default function Avatar({image, initials}) {

  return (
  <div className="avatar">
      <div className="avatar--image">
        {image 
          ? <img src={image} alt="" />
          : <span>{initials ?? 'AN'}</span>
        }
      </div>
  </div>
  )
}
