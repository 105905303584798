import React from 'react';
import { useNavigate } from 'react-router-dom';
import Page from '../Page';
import imageSocrates from '../assets/images/socrates-480.jpg'
import imageNh from '../assets/images/nh-hrad-480.png'
import imagePrompt from '../assets/images/image-prompt-480.png'
import imageTemp from '../assets/images/image-temp-480.png'


export default function Front() {

  const navigate = useNavigate();

  return (
    <Page>
    <div className="Front">

      <div className="banners">
        <div className="banner"  onClick={() => navigate("/image-from-prompt")}>
          <img src={imagePrompt} alt="" />
          <p>Simple prompt-based image generation</p>
        </div>
        <div className="banner" onClick={() => navigate("/image-from-templates")}>
          <img src={imageTemp} alt="" />
          <p>Image generation with pre-made prompt templates with background and foreground description.</p>
        </div>
        <div className="banner" onClick={() => navigate("/socratic-ai")}>
          <img src={imageSocrates} alt="" />
          <p>AI chatbots representing Socrates and Plato have fun talking to each other.</p>
        </div>
        <div className="banner" onClick={() => navigate("/nh-guide")}>
         <img src={imageNh} alt="" />
          <p>Chatbot about Nove Hrady.</p>
        </div>
      </div>
    </div>
  </Page>
  )
}
