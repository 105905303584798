import React, { useState } from 'react';
import Toolbar from './components/Toolbar';
import { dict } from './lib/constants';


import MessagePanel from './components/MessagePanel';
import './Page.scss';

export default function Page({ children, className }) {

  const [lang, setLang] = useState('en');

  const t = (word) => {
    if (lang !== 'en') {
      const translation = dict.find( ({k, v}) => k === word);
      if (translation)
        return translation[word];
      return word;
    }
    return word;
  }



  return (
    <div className={`page ${className ?? ''}`}>
      <MessagePanel />
      <Toolbar lang={lang} setLang={setLang} t={t} />
      <main className="main">
        {children}
      </main>
    </div>
  );
}
