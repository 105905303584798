import React, { useState, useEffect } from "react";
import Loader from './Loader';
import Avatar from "./Avatar";
import { nanoid } from 'nanoid';
import { oaiChatEndpoint } from "../lib/constants";
import './Dialogue.scss';

export default function Dialogue({ questioner, answerer }) {

  const [topic, setTopic] = useState('');
  const [speech, setSpeech] = useState('');
  const [chat, setChat] = useState([]);
  const [chatQ, setChatQ] = useState([]);
  const [chatA, setChatA] = useState([]);
  const [convo, setConvo] = useState(nanoid());
  const [loading, setLoading] = useState(false);
  const [talking, setTalking] = useState(questioner);


  const handleSpeech = async () => {
    setLoading(true);
    let a = answerer;
    let q = questioner;

    try {
      if (talking === q) {
        const response = await fetch(oaiChatEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
          },
          body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            messages: [
              { "role": "system", "content": q.intro + 'Current discussion topic: ' + topic },
              ...chatQ,
              { "role": 'user', "content": speech ?? topic }
            ],
            max_tokens: 250,
            user: q.id + '_' + convo,
          })
        });

        const data = await response.json();
        // console.log('data', data);
        const lastSaid = data.choices[0].message.content;

        setChatQ(prev => [...prev, { role: 'user', content: speech }, { role: 'assistant', content: lastSaid }]);
        setChat(prev => [...prev, { role: q.id, content: lastSaid }]);
        setTalking(answerer);
        setSpeech(lastSaid);
        setLoading(false);

      } else { // a is talking

        const response = await fetch(oaiChatEndpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + process.env.REACT_APP_OPENAI_API_KEY,
          },
          body: JSON.stringify({
            model: 'gpt-3.5-turbo',
            messages: [
              { "role": "system", "content": a.intro },
              ...chatA,
              { "role": 'user', "content": speech }
            ],
            max_tokens: 250,
            user: a.id + '_' + convo,
          })
        });

        const data = await response.json();
        // console.log('data', data);
        const lastSaid = data.choices[0].message.content;

        setChatA(prev => [...prev, { role: 'user', content: speech }, { role: 'assistant', content: lastSaid }]);
        setChat(prev => [...prev, { role: a.id, content: lastSaid }]);
        setTalking(questioner);
        setSpeech(lastSaid);
        setLoading(false);

      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const reset = () => {
    setTopic('');
    setSpeech('');
    setChat([]);
    setChatQ([]);
    setChatA([]);
    setConvo(nanoid());
    setTalking(questioner);
  }

  useEffect(() => {
    setTopic('');
    setSpeech('');
    setChat([]);
    setChatQ([]);
    setChatA([]);
    setConvo(nanoid());
    setTalking(questioner);
  }, [questioner, answerer]);



  return (
    <div className="Dialogue">

      <div className="box-row">
        <div className="container">
        <header className="top-grid">
          <div className="col-24">
            <h2>Dialogues</h2>
          </div>
          
          <div className="col-24">
            <img className="wide-border" src={questioner.image} alt={questioner.name} id={questioner.id} />
            <h3>{questioner.name}</h3>
          </div>
          
          <div className="col-24">
            <img className="wide-border" src={answerer.image} alt={answerer.name} id={answerer.id} />
            <h3>{answerer.name}</h3>
          </div>

          <div className="col-24">
            {speech ? <button className="mtl-btn s secondary" onClick={reset}>Restart</button> 
            : <p>Set topic below and press Start.</p> }
          </div>
        </header>

        {!speech && (
          <div className="talkbox">
            <input
              type="text"
              className='talkbox__input'
              placeholder={'Set Discussion Topic'}
              value={topic}
              onChange={e => {
                setTopic(e.target.value);
              }}
              onKeyDown={e => e.key === 'Enter' && handleSpeech()}
            />
            <button className="mtl-btn" onKeyDown={(e) => e.key === 'Enter' ? handleSpeech() : ''} onClick={handleSpeech}>
              Start
            </button>
          </div>
          
        )}
        </div>
      </div>

      {loading
        ? <Loader />
        :
        <div className="box-row chat">
          {chat?.map((item, i) =>
            <div key={nanoid()} className={`chat__item dialogue ${item.role}`}>
              <Avatar image={answerer.id === item.role ? answerer.image : questioner.image} />
              <p>{item.content}</p>
              {(i === chat.length - 1) &&
                <div className="meta">
                  <button className="mtl-btn primary s" onClick={handleSpeech}>Continue</button>
                </div>
              }
            </div>
          )}
        </div>
      }

    </div>
  );
}

